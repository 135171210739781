<template>
    <layout>
        <v-container>
            <v-card flat>
                <v-card-text>
                    <div class="text-32-60 mb-10 text-center">
                        Terms and Conditions
                    </div>
                    <div v-for="item in text" class="mb-8">
                        <div :class="item.titleType == 'main' ? `text-28-60` : `text-20-60`" class="mb-4" v-html="item.title"></div>
                        <p v-for="paragraph in item.text" v-html="paragraph"></p>
                    </div>
                    <div class="">
                        <p>Freight Crew LLC</p>
                        <p>Paste address</p>
                        <p class="mb-6">Paste city, Alabama 1255</p>
                        <p>Email Address:</p>
                        <p class="mb-6">info@freightcrew.us</p>
                        <p>Telephone number:</p>
                        <p class="mb-6">000-000-000</p>
                        <p>Effective as of August 21, 2020</p>
                    </div>
                </v-card-text>
            </v-card>
        </v-container>
    </layout>
</template>

<script>
import Layout from '@/components/layouts/LightTopLayout.vue'

export default {
    components: {
        Layout,
    },
    data: () => ({
        text: [
            {
                titleType: 'main',
                title: 'Agreement between User and Freightcrew.us',
                textType: 'text',
                text: [
                    'Welcome to Freightcrew.us. The Freightcrew.us website (the "Site") consists of various web pages operated by Freight Crew LLC ("Freight Crew"). Freightcrew.usis offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of Freightcrew.us constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.',
                    'By accessing this website we assume you accept these terms and conditions. Do not continue to use this website if you do not agree to take all of the terms and conditions stated on this page.',
                    'The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company\'s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client\'s needs in respect of provision of the Company\'s stated services, in accordance with and subject to, prevailing law of the USA . Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to the same.'
                ]
            },
            {
                titleType: 'subtitle',
                title: 'Freightcrew.us is an E-Commerce Site (choose the site type).',
                textType: 'text',
                text: [
                    'Freight Crew is a full cycle transportation company specialized in making time-limited deliveries in the US, Canada, and Mexico. We are a team of forward-thinking shipment professionals, who provide real solutions to time-critical needs of our clients, so they can focus on their company’s goals while we ensure the highest level of services.',
                    'Our main office is located in the US, but our agents and partners also work in Canada and Mexico. We provide freight shipping not only between capitals, but between any cities in Canada, USA and Mexico.'
                ]
            },
            {
                titleType: 'main',
                title: 'Privacy',
                textType: 'text',
                text: [
                    'Your use of Freightcrew.us is subject to Freight Crew\'s Privacy Policy. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices.'
                ]
            },
            {
                titleType: 'main',
                title: 'Electronic Communications',
                textType: 'text',
                text: [
                    'Visiting Freightcrew.us or sending emails to Freight Crew constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.'
                ]
            },
            {
                titleType: 'main',
                title: 'Your Account',
                textType: 'text',
                text: [
                    'If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that Freight Crew is not responsible for third party access to your account that results from theft or misappropriation of your account. Freight Crew and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content at our sole discretion.'
                ]
            },
            {
                titleType: 'main',
                title: 'Children Under Thirteen',
                textType: 'text',
                text: [
                    'Freight Crew does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use Freightcrew.us only with permission of a parent or guardian.'
                ]
            },
            {
                titleType: 'main',
                title: 'Links to Third Party Sites/Third Party Services',
                textType: 'text',
                text: [
                    'Freightcrew.us may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of Freight Crew and Freight Crew is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. Freight Crew is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by Freight Crew of the site or any association with its operators.',
                    'Certain services made available via Freightcrew.us are delivered by third party sites and organizations. By using any product, service or functionality originating from the Freightcrew.us domain, you hereby acknowledge and consent that Freight Crew may share such information and data with any third party with whom Freight Crew has a contractual relationship to provide the requested product, service or functionality on behalf of Freightcrew.us users and customers.',
                ]
            },
            {
                titleType: 'main',
                title: 'No Unlawful or Prohibited Use/Intellectual Property',
                textType: 'text',
                text: [
                    'You are granted a non-exclusive, non-transferable, revocable license to access and use Freightcrew.us strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to Freight Crew that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party\'s use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.',
                    'All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of Freight Crew or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.',
                    'You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. Freight Crew content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of Freight Crew and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of Freight Crew or our licensors except as expressly authorized by these Terms.'
                ]
            },
            {
                titleType: 'main',
                title: 'International Users',
                textType: 'text',
                text: [
                    'The Service is controlled, operated and administered by Freight Crew from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the Freight Crew Content accessed through Freightcrew.us in any country or in any manner prohibited by any applicable laws, restrictions or regulations.'
                ]
            },
            {
                titleType: 'main',
                title: 'Indemnification',
                textType: 'text',
                text: [
                    'You agree to indemnify, defend and hold harmless Freight Crew, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney\'s fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. Freight Crew reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with Freight Crew in asserting any available defenses.'
                ]
            },
            {
                titleType: 'main',
                title: 'Arbitration',
                textType: 'text',
                text: [
                    'In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator\'s award shall be final, and judgment may be entered upon it in any court having jurisdiction. In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to recover its costs and reasonable attorney\'s fees. The parties agree to arbitrate all disputes and claims in regards to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator. This arbitration provision shall survive the termination of these Terms and Conditions.'
                ]
            },
            {
                titleType: 'main',
                title: 'Liability Disclaimer',
                textType: 'text',
                text: [
                    'THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. FREIGHT CREW LLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.',
                    'FREIGHT CREW LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. FREIGHT CREW LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.',
                    'TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL FREIGHT CREW LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF FREIGHT CREW LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.'
                ]
            },
            {
                titleType: 'main',
                title: 'Termination/Access Restriction',
                textType: 'text',
                text: [
                    'Freight Crew reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of _________________ (paste the state/states) and you hereby consent to the exclusive jurisdiction and venue of courts in _________________ (paste the state/states) in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.',
                    'You agree that no joint venture, partnership, employment, or agency relationship exists between you and Freight Crew as a result of this agreement or use of the Site. Freight Crew\'s performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of Freight Crew\'s right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by Freight Crew with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.',
                    'Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and Freight Crew with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and Freight Crew with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.',

                ]
            },
            {
                titleType: 'main',
                title: 'Changes to Terms',
                textType: 'text',
                text: [
                    'Freight Crew reserves the right, in its sole discretion, to change the Terms under which Freightcrew.us is offered. The most current version of the Terms will supersede all previous versions. Freight Crew encourages you to periodically review the Terms to stay informed of our updates.'
                ]
            },
            {
                titleType: 'main',
                title: 'Contact Us',
                textType: 'text',
                text: [
                    'Freight Crew welcomes your questions or comments regarding the Terms:'
                ]
            },
        ]
    })
}
</script>

<style lang="scss">
</style>
<!-- {
    titleType: '',
    title: '',
    textType: 'text',
    text: []
}, -->
